import React from "react"
import loadable from "@loadable/component"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import SafeHandsImg from "../images/safe_hands_img.png"

const Banner = loadable(() => import("../components/Banner/Banner"))
const ContactOffice = loadable(() => import("../components/ContactOffice/ContactOffice"))
const ContactMapModule = loadable(() => import("../components/ContactMapModule/ContactMapModule"))
const SafeHands = loadable(() => import("../components/SafeHands/SafeHands"))
const WhatDoYouNeed = loadable(() => import("../components/WhatDoYouNeed/WhatDoYouNeed"))
const SellLetHome = loadable(() => import("../components/SellLetHome/SellLetHome"))

const Contact = ({ data }) => {
    const PageData = data?.strapiPage
    const Offices = data?.allStrapiOffice.edges
    return (
        <Layout popularSearch={PageData?.select_popular_search?.title}>
            <Banner
                showBreadCrumb
                breadcrumbData={PageData.choose_menu[0]}
                pagename={PageData.title}
                {...PageData.banner}
                title={PageData.banner?.banner_title}
                id={PageData.strapi_id}
                imagetransforms={PageData.imagetransforms}
                btnPrimaryIconClass="icon-banner-valuation"
                btnPrimaryLightIconClass="icon-valuation-home"
            />

            {PageData?.add_page_modules?.length > 0 && PageData.add_page_modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "offices" && <>
                            <ContactOffice Offices={Offices} {...module} />
                            <ContactMapModule Offices={Offices} />
                        </>}
                        {module.strapi_component === "page-modules.services-tiles" && <WhatDoYouNeed id={PageData.strapi_id} {...module} />}
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "google_review_slider" && <>
                            <SafeHands {...module} Img={SafeHandsImg} /><div className="border-line"></div></>}
                        {module.strapi_component === "page-modules.plain-content" && module.layout === "bg_color_center_align" && <SellLetHome {...module} />}
                    </>
                )
            }
            )}
        </Layout>
    )
}


export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default Contact


export const query = graphql`
    query ($page_id: String) {
      strapiPage(id: {eq: $page_id}) {
          ...PageFragment
          add_page_modules {
                ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
                    ...GlobalModuleFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
                    ...ImageAndContentFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                    ...PlainContentFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_SERVICES_TILES {
                    ...ServicesTilesFragment
                }
                ... on STRAPI__COMPONENT_PAGE_MODULES_STATISTICS {
                    ...StatsFragment
                }
          }
      }
      allStrapiOffice(filter: {publish: {eq: true}}) {
          edges {
              node {
                  ...OfficeFragment
              }
          }
      }
    }
  `